import { useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"
import MainClient from "../../utilities/MainClient"
import NumberFormat from 'react-number-format';
import numberWithOutCommas from "../../utilities/extrafunction/numberWithoutComma";


const CashFlows = ({ opCashFlow, setOpCashFlow, cpCashFlow, setCpCashFlow, terms, setTerms, updateHandler, submit, enableUpdate = true }) => {
    const [update, setUpdate] = useState(false)
    // const [opCashFlow, setOpCashFlow] = useState([])
    // const [cpCashFlow, setCpCashFlow] = useState([])
    const [loading, setLoading] = useState(true)
    // const [submit, setSubmit] = useState(false)
    // const [terms, setTerms] = useState(0)
    const { id } = useParams()

    useEffect(() => {
        let op = []
        let cp = []
        for (let i = 1; i <= terms; i++) {
            op.push({ year: i, value: '' },)
            cp.push({ year: i, value: '0' },)
        }
        console.log('opCashFlow', opCashFlow)
        if (opCashFlow.length > 0 && terms > opCashFlow.length) {
            let opf = [...opCashFlow]
            let cpf = [...cpCashFlow]
            for (let i = opf.length + 1; i <= terms; i++) {
                opf.push({ year: i, value: '' },)
                cpf.push({ year: i, value: '0' },)
            }
            setOpCashFlow(opf)
            setCpCashFlow(cpf)
        }
        else if (opCashFlow.length > terms) {
            let opf = [...opCashFlow].slice(0, terms)
            let cpf = [...cpCashFlow].slice(0, terms)
            setOpCashFlow(opf)
            setCpCashFlow(cpf)
        }
        else if (opCashFlow.length > 0 || cpCashFlow.length > 0) {
            setOpCashFlow(opCashFlow)
            setCpCashFlow(cpCashFlow)
        }
        else {
            setOpCashFlow(op)
            setCpCashFlow(cp)
        }
    }, [terms])

    const addNewOp = (e) => {
        e.preventDefault()
        const year = opCashFlow.length + 1
        const data = {
            year: year,
            value: ''
        }
        opCashFlow.push(data)
        setOpCashFlow(opCashFlow)
        setUpdate(!update)
    }

    const addNewCp = (e) => {
        e.preventDefault()
        const year = cpCashFlow.length + 1
        const data = {
            year: year,
            value: ''
        }
        cpCashFlow.push(data)
        setCpCashFlow(cpCashFlow)
        setUpdate(!update)
    }

    const changeOpInput = (e) => {
        const { name, value, id } = e.target
        const item = {
            year: id,
            name: name,
            value: value
        }
        const currentValue = opCashFlow.slice()
        const newOp = currentValue.map(op_item => {
            for (var key in op_item) {
                if (key === "value" && op_item.year == item.year) {
                    op_item[key] = numberWithOutCommas(item.value)
                }
            }
            return op_item
        })
        setOpCashFlow(newOp)
    }

    const changeCpInput = (e) => {
        const { name, value, id } = e.target
        const item = {
            year: id,
            value: value
        }
        const currentValue = cpCashFlow.slice()
        const newCp = currentValue.map(cp_item => {
            for (var key in cp_item) {
                if (key === "value" && cp_item.year == item.year) {
                    cp_item[key] = numberWithOutCommas(item.value)
                }
            }
            return cp_item
        })
        setCpCashFlow(newCp)
    }
    const opInput = opCashFlow.map(dt =>
        <div class="col-sm-6 col-md-3" key={dt.year}>
            <label for="year1" class="form-label">{dt.year} year</label>
            <NumberFormat
                id={dt.year}
                name={dt.name}
                value={dt.value}
                class="form-control"
                thousandSeparator={true}
                onChange={changeOpInput}
                placeholder='Enter Amonut'
            />

        </div>
    )
    // console.log("cop", opInput)

    const cpInput = cpCashFlow.map(dt =>
        <div class="col-sm-6 col-md-3" key={dt.year}>
            <label for="cap1" class="form-label">{dt.year} year</label>
            <NumberFormat
                id={dt.year}
                name={dt.name}
                value={dt.value}
                class="form-control"
                thousandSeparator={true}
                onChange={changeCpInput}
                placeholder='Enter Amonut'
            />
        </div>
    )

    // console.log('op', opCashFlow)
    let T = []
    const CP = []
    const CashFlow = opCashFlow.map(dt =>
        dt.value && T.push(parseInt(dt.value))
    )

    const SeconedCashFlow = cpCashFlow.map(dt =>
        dt.value && CP.push(parseInt(dt.value))
    )
    let totalOpCashFlow = numberWithCommas(T.reduce((a, b) => a + b, 0))
    let totalCpCashFlow = numberWithCommas(CP.reduce((a, b) => a + b, 0))
    console.log('totalOp', totalOpCashFlow)

    const termHandler = (e) => {
        // console.log('selected', e.target.value)
        if (e.target.value > 10) {
            return
        }
        else {
            setTerms(e.target.value)
        }
    }

    return (
        <div class="mb-3 pb-1">
            <div class="col-12">
                <div class="mb-2">
                    <div class="card-body">
                        <div class='mt-2'>
                            <h2 class='mb-0 fs-18 fw-6 text-black'>Cash Flows</h2>
                        </div>
                        <div className="mt-4">
                            <div className="mb-3">
                                <h6 className="fs-6 text-black fw-4 lh-1">Investment Terms</h6>
                            </div>
                            <div class="col-sm-6 col-md-3" >
                                <label for="cap1" class="form-label">Terms</label>
                                <input type="number" class="form-control" value={terms} onChange={termHandler} max='10' min='1' />
                            </div>
                            {/* <select class="form-select form-select-sm" aria-label=".form-select-sm example" value={terms} onChange={(e) => setTerms(e.target.value)}>
                                <option selected disabled>Select Term</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                <option value="4">Four</option>
                                <option value="5">Five</option>
                                <option value="6">Six</option>
                                <option value="7">Seven</option>
                                <option value="8">Eight</option>
                                <option value="9">Nine</option>
                                <option value="10">Ten</option>
                            </select> */}
                        </div>
                        <div class="data-body">
                            <div class="mt-4 mb-3">
                                <h2 class="fs-16 text-black fw-5 mb-0">Operating Cash Flows</h2>
                            </div>
                            <form action="">
                                <div class="row g-3 mb-4 align-items-center" id="operatingYear">
                                    {opInput}
                                    <div class="col-sm-6 col-md-3">
                                        <label for="total" class="form-label">Total</label>
                                        <input type="text" class="form-control" id="total"
                                            placeholder='Enter cash flow' value={totalOpCashFlow} />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class=''>
                            <h2 class='mt-4 mb-3 fs-16 fw-5 text-black'>Capital Event Cash Flows</h2>
                        </div>
                        <div class="data-body ce-cash-card">
                            <form action="">
                                <div class="row g-3 mb-4 align-items-center" id="capitalYear">
                                    {cpInput}
                                    <div class="col-sm-6 col-md-3">
                                        <label for="total" class="form-label">Total</label>
                                        <input type="text" class="form-control" id="total" value={totalCpCashFlow}
                                            placeholder='Enter cash flow' />
                                    </div>
                                </div>
                            </form>

                            {/* <div class="row mb-3 align-items-center justify-content-end">

                                <div class="col-auto ">
                                    {submit ?
                                        <button class="btn btn-primary ms-1 save-btn" type="button" disabled>
                                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            Saving...
                                        </button> :
                                        <button class="btn btn-success ms-1 save-btn" onClick={submitHandler}>Save</button>}
                                </div>
                            </div> */}
                            {/* <div className="d-flex justify-content-end">
                                    <button className="btn btn-md btn-primary" type="submit" onClick={submitHandler}>Submit</button>
                                </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {enableUpdate && (<>
            {id &&
                <div class="row m-3 mt-0 align-items-center justify-content-end">
                    <div class="col-auto ">
                        {submit ?
                            <button class="btn ms-1 save-btn" type="button" disabled style={{ background: "#2b4479" }}>
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Update...
                            </button> :
                            <button class="btn btn-success save-btn" onClick={updateHandler} style={{ background: "#2b4479" }}>Update</button>}
                    </div>
                </div>}
                </>)}

        </div>
    )
}
export default CashFlows