import { InputNumber, Space } from "antd"
import { useEffect, useRef, useState } from "react"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"

const MetricsCalculator = ({ total, metrics, setMetrics, entryNoiSelect, setEntryNoiSelect, stabilizedNoiSelect, setStabilizedNoiSelect, proNoiSelect, setProNoiSelect, updateHandler, id, submit }) => {
    const iEleStabilized = useRef(null)
    useEffect(() => {
        if (iEleStabilized.current) {
            iEleStabilized.current.focus();
        }
    }, [iEleStabilized]);
    // const [metrics, setMetrics] = useState({
    //     purchase_price: '',
    //     closing_cost: '',
    //     capex: '',
    //     reserves: ''
    // })
    // const [entryNoiSelect, setEntryNoiSelect] = useState({
    //     purchase_price: false,
    //     closing_cost: false,
    //     capex: false,
    //     reserves: false,
    // })
    // const [stabilizedNoiSelect, setStabilizedNoiSelect] = useState({
    //     purchase_price: false,
    //     closing_cost: false,
    //     capex: false,
    //     reserves: false,
    // })
    // const [proNoiSelect, setProNoiSelect] = useState({
    //     purchase_price: false,
    //     closing_cost: false,
    //     capex: false,
    //     reserves: false,
    // })
    const reservesChange = (value) => {
        setMetrics({ ...metrics, reserves: value })
    }
    const purchasePriceChange = (value) => {
        setMetrics({ ...metrics, purchase_price: value })
    }
    const closingCostChange = (value) => {
        setMetrics({ ...metrics, closing_cost: value })
    }
    const capexChange = (value) => {
        setMetrics({ ...metrics, capex: value })
    }
    const entrynoiChange = (value) => {
        setMetrics({ ...metrics, entry_noi: value })
    }
    const stabilizednoiChange = (value) => {
        setMetrics({ ...metrics, stabilized_noi: value })
    }
    const pronoiChange = (value) => {
        setMetrics({ ...metrics, pro_noi: value })
    }
    console.log("metrix", metrics)

    // const total = Object.values(metrics).slice(0, 4).reduce((a, b) => a + b, 0)
    const entryNoiBasis = [(entryNoiSelect.purchase_price && metrics.purchase_price), (entryNoiSelect.closing_cost && metrics.closing_cost), (entryNoiSelect.capex && metrics.capex), (entryNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    const stabilizedNoiBasis = [(stabilizedNoiSelect.purchase_price && metrics.purchase_price), (stabilizedNoiSelect.closing_cost && metrics.closing_cost), (stabilizedNoiSelect.capex && metrics.capex), (stabilizedNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    const proNoiBasis = [(proNoiSelect.purchase_price && metrics.purchase_price), (proNoiSelect.closing_cost && metrics.closing_cost), (proNoiSelect.capex && metrics.capex), (proNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)

    return (
        <div class='row' style={{ fontFamily: 'Verdana' }}>
            <div class='col-12 matrix-card'>
                <div class=''>
                    <div className="p-2">
                        <div class='mb-4 mt-2'>
                            <div class='d-flex justify-content-between align-content-center'>
                                <h2 class='mb-0 fs-16 fw-6 text-black'>Metrics and Capitalization :</h2>
                                <h2 class='mb-0 fs-16 fw-5' style={{ color: "#A2ACBD", letterSpacing: '0.2px', }}>Total
                                    <span class='fw-bold text-black'> ${numberWithCommas(total)}</span>
                                    {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                                </h2>
                            </div>
                        </div>
                        <div className=''>
                            <form action="" id="deal-form" >
                                <div class="row g-3">
                                    <div class="col-sm-6 col-md-3 ">
                                        <label for="purchasePrice" class="form-label">Purchase Price</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                                defaultValue={metrics.purchase_price}
                                                placeholder="Enter Purchase Price"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                name="purchase_price"
                                                onChange={purchasePriceChange}
                                            />
                                        </Space>
                                    </div>
                                    <div class="col-sm-6 col-md-3 ">
                                        <label for="closingCost" class="form-label">Closing Costs</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                                defaultValue={metrics.closing_cost}
                                                placeholder="Enter Closing Costs"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                name="closing_cost"
                                                onChange={closingCostChange}
                                            />

                                        </Space>
                                        {/* <input type="number" class="form-control" id="closingCost"
                                            placeholder='Enter Closing Costs'
                                            name="closing_cost"
                                            value={property.closing_cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            onChange={changeHandler}
                                            required
                                        /> */}
                                    </div>
                                    <div class="col-sm-6 col-md-3 ">
                                        <label for="reserves" class="form-label">Capex</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                                defaultValue={metrics.capex}
                                                placeholder="Enter Capex"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                // name="closing_cost"
                                                onChange={capexChange}
                                            />
                                        </Space>
                                    </div>
                                    <div class="col-sm-6 col-md-3 ">
                                        <label for="reserves" class="form-label">Reserves</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                                defaultValue={metrics.reserves}
                                                placeholder="Enter Reserves"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                // name="closing_cost"
                                                onChange={reservesChange}
                                            />
                                        </Space>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default MetricsCalculator