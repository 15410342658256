import { message } from 'antd';
import React, { useEffect, useState, useRef } from "react";
import {
    Typography,
    TextField,
    Stepper,
    Step,
    StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { fetchOpenDeal } from '../store/actions/DealAction';
import Metrics from '../components/newSponsor/Metrics';
import CashFlows from '../components/newSponsor/CashFlows';
import CapitalStack from '../components/newSponsor/CapitalStack';
import FinalResult from '../components/newSponsor/FinalResult';
import MainClient from '../utilities/MainClient';
import WaterFall from '../components/newSponsor/Waterfall';
import { Container, Navbar } from 'react-bootstrap';
import MetricsCalculator from '../components/DealCalculator/MetricsCalculator';
import WaterfallCalculator from '../components/DealCalculator/WaterfallCalculator';
import { Button } from '@mui/material';
import Result from '../components/newSponsor/Result';
import CapitalResult from '../components/newSponsor/CapitalResult';
import ReplyIcon from "@mui/icons-material/Reply";
import { Modal } from "react-bootstrap";


function getSteps() {
    return [
        "Property Info",
        "Metrics & Capitalization",
        "Cash Flows",
        "Capital Stack and Waterfall",
        "Waterfall Summary",
        "Deal Summary",
    ];
}
const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));




const DealCalculator = (step, setActiveStep) => {
    const [update, setUpdate] = useState(false)
    const [capitalResult, setCapitalResult] = useState()
    const [result, setResult] = useState()
    const [submit, setSubmit] = useState(false)
    const [cashFlowId, setCashFlowId] = useState()
    const [capId, setCapId] = useState()
    const [waterfallId, setWaterfallId] = useState()
    const [images, setImages] = useState()
    const [document, setDocument] = useState()
    const [imageData, setImageData] = useState()
    const [documentData, setDocumentData] = useState()
    const [hurdle, setHurdle] = useState('None');
    const [errorMessage, setErrorMessage] = useState()
    const [propertyList, setPropertyList] = useState([]);
    const [WaterfallTable, setWaterfallTable] = useState();
    const [dealId, setDealId] = useState(localStorage.getItem('currentDealId') || null);
    const [isLoading, setIsLoading] = useState(false);
    const [showResult, setShowResult] = useState(false);

    const linkRef = useRef(null);
    const [currentUserEmail, setCurrentUserEmail] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [PublicUrlPage, setPublicUrlPage] = useState("");
    const [NewPublicUrl, setNewPublicUrl] = useState("");
    const [valid, setIsValid] = useState(false);
    const mainClientInstance = new MainClient(/* url, data, header */);
    const [verifiedShared, setVerifiedShared] = useState(false);

    // ***** metics start ******* //
    const [metrics, setMetrics] = useState({
        purchase_price: '',
        closing_cost: '',
        capex: '',
        reserves: '',
        entry_noi: '',
        stabilized_noi: '',
        pro_noi: '',
        entry_capbasis: '',
        stabilized_capbasis: '',
        pro_capbasis: ''
    })

    const { id } = useParams();

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const user_id = localStorage.getItem("user_id")


    useEffect(() => {
        async function fetchData() {
            try {
                const fetchClient = new MainClient('/deal/api/sharecalculator/');
                const response = await fetchClient.getNewPublicUrl();
                const data = response.data;
                console.log("ShareCalculator Data", data)
                data.forEach((item) => {
                    if (id) {
                        console.log("My item from Calculator", item)
                        localStorage.setItem("temp_token", item.token);
                        localStorage.setItem('currentDealId', item.deal_id);
                        //   setDealUser(item.deal_user);
                        setDealId(item.deal_id);

                    }
                });
            }
            catch (error) {
                console.log("Error fetcing data:", error);
            }
        }

        fetchData();
    }, [id]);

    useEffect(() => {
        const getData = (type, id) => {
            const fetchClient = new MainClient(`/investor/${type}-profile/${id}/`);
            const response = fetchClient.getAPI();
            response.then((res) => {
                setCurrentUserEmail(
                    `${res.data.user.email},${res.data.user.first_name}`
                );
                // setData((res.data.results).reverse())
            });
        };
        const getUserData = () => {
            const fetchClient = new MainClient(`/user/user-details`);
            const response = fetchClient.getAPI();
            response.then((res) => {
                const user_type = res.data.user_type;
                const profile_id = res.data.profile_id;

                console.log("responseUser", profile_id, user_type);
                getData(user_type, profile_id);
            });
        };
        // getData()
        getUserData();
    }, []);



    const handleDelete = async () => {
        if (!dealId) {
            message.error('No deal to delete');
            return;
        }

        setIsLoading(true);
        try {
            const fetchClient = new MainClient(`/deal/api/view-add-deal/${dealId}/`);
            await fetchClient.instance.delete(fetchClient.url, {
                headers: fetchClient.getHeader().headers
            });

            // Clear local storage
            localStorage.removeItem('currentDealId');
            setDealId(null);

            // Reset all state variables
            setProperty({
                "deal_name": "",
                "property_title": "",
                "address": "",
                "city": "",
                "state": "",
                "units": '',
                "description": "",
                "square_feet": ""
            });
            setMetrics({
                purchase_price: '',
                closing_cost: '',
                capex: '',
                reserves: '',
                entry_noi: '',
                stabilized_noi: '',
                pro_noi: '',
                entry_capbasis: '',
                stabilized_capbasis: '',
                pro_capbasis: ''
            });
            setOpCashFlow([]);
            setCpCashFlow([]);
            setData([{
                capital_title: 'Class A Equity Shares',
                capital_stack: '',
                equity: 0,
                LP: 0,
                Sponsor: 0,
                amount: '',
                purchase_basis: 0,
                total_capital_basis: 0,
                pref: '',
                min_equity_multiple: "0",
                irr_hurdle: "0",
                profit_share: ''
            }]);
            setResult(null);
            setCapitalResult(null);

            message.success('Deal deleted successfully');
            navigate('/deal-calculator')
        } catch (error) {
            console.error('Error deleting deal:', error);
            message.error('Failed to delete deal. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const getData = () => {
        const fetchClient = new MainClient(`/deal/api/view-add-deal/${dealId}/`)
        const response = id ? fetchClient.getPublicURL() : fetchClient.getAPI()

        response.then(res => {
            const data = res.data
            console.log('da2', data)

            const purchase_price = data.purchase_price > 0 ? data.purchase_price : 0
            const closing_cost = data.closing_cost > 0 ? data.closing_cost : 0
            const capex = data.capex > 0 ? data.capex : 0
            const reserves = data.reserves > 0 ? data.reserves : 0

            const total = parseInt(purchase_price) + parseInt(closing_cost) + parseInt(capex) + parseInt(reserves)

            setDocumentData(data.upload_document)
            setImageData(res.data.image_data)
            setProperty(res.data)

            setMetrics({
                purchase_price: parseInt(data.purchase_price),
                closing_cost: parseInt(data.closing_cost),
                capex: parseInt(data.capex),
                reserves: parseInt(data.reserves),
                entry_noi: data.entry_noi,
                stabilized_noi: data.stabilized_noi,
                pro_noi: data.proForma_noi
            })

            setEntryNoiSelect(data.noi_data[0])
            setStabilizedNoiSelect(data.noi_data[1])
            setProNoiSelect(data.noi_data[2])
            setTerms(data.investment_term)

            setSeniorDept(prevState => ({
                ...prevState,
                purchase_basis: parseInt(data.purchase_price),
                total_capital_basis: total,
            }))

            console.log("senior Dept after update:", seniorDept, data.purchase_price)
        })
    }

    const getCashflow = async () => {
        try {
            const fetchClient = new MainClient(`/deal/view-deal-cash-flow/?deal_id=${dealId}`);
            const response = id ? await fetchClient.getPublicURL() : await fetchClient.getAPI();
            console.log('Cashflow data:', response.data);
            setOpCashFlow(response.data[0].op_cash_flow_years);
            setCpCashFlow(response.data[0].cp_cash_flow_years);
            setCashFlowId(response.data[0].id);
        } catch (error) {
            console.error('Error fetching cashflow data:', error);
            // message.error('Failed to fetch cashflow data');
        }
    };

    // Additional Property Data
    const getAdditionPropertyInfo = () => {

        const fetchClient = new MainClient(`/deal/getpropertyinfodeal/${id}/`)
        const response = fetchClient.getAPI()

        // axios
        // .get(`http://127.0.0.1:8000/deal/getpropertyinfodeal/${id}/`)
        response
            .then((response) => {
                const data = response.data;
                const properties = data.map((item) => item.fields);
                setPropertyList(properties);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getCapitalStack = async () => {
        try {
            const fetchClient = new MainClient(`/deal/view-capital_stack/?deal_id=${dealId}`);
            const response = id ? await fetchClient.getPublicURL() : await fetchClient.getAPI();
            const data = response.data[0];
            console.log('Capital stack data:', data);
            setSeniorDept({
                capital_stack: data.senior_debt,
                amount: data.senior_debt_amount,
            });
            setData(data.classesData);
            setDataLen(data.classesData.length);
            setCapId(data.id);
            setHurdle(data.hurdle);
        } catch (error) {
            console.error('Error fetching capital stack data:', error);
            // message.error('Failed to fetch capital stack data');
        }
    };

    const getWaterfall = async () => {
        try {
            const fetchClient = new MainClient(`/deal/waterfall/${dealId}/`);
            const response = id ? await fetchClient.getPublicURL() : await fetchClient.getAPI();
            console.log('Waterfall data:', response.data);
            if (response.data && response.data.response) {
                setResult(response.data.response);
                setErrorMessage(response.data.warning_message);
            } else {
                console.error('Unexpected response format:', response.data);
                setErrorMessage('Unexpected response format');
            }
        } catch (error) {
            console.error('Error fetching waterfall data:', error);
            // message.error('Failed to fetch waterfall data');
        }
    };

    const getEquityWaterfall = async () => {
        try {
            const fetchClient = new MainClient(`/deal/equity-waterfall/?deal_id=${dealId}`);
            const response = id ? await fetchClient.getPublicURL() : await fetchClient.getAPI();
            console.log('Equity waterfall data:', response.data);
            const data = response.data[0];
            setOpdata(data.op_waterfall);
            setCpdata(data.cp_waterfall);
            setWaterfallId(data.id);
        } catch (error) {
            console.error('Error fetching equity waterfall data:', error);
            // message.error('Failed to fetch equity waterfall data');
        }
    };


    const getCapitalResult = async () => {
        try {
            const fetchClient = new MainClient(`/deal/waterfall-capital-event/${dealId}/`);
            const response = await fetchClient.getAPI();
            console.log('Capital result data:', response.data);
            setCapitalResult(response.data.result1);
        } catch (error) {
            console.error('Error fetching capital result data:', error);
            // message.error('Failed to fetch capital result data');
        }
    };

    useEffect(() => {
        if (dealId) {
            getData()
            getAdditionPropertyInfo()
        } else {
            setImageData('')
            setDocumentData('')
            setDocument('')
            setProperty({
                "deal_name": "",
                "property_title": "",
                "address": "",
                "city": "",
                "state": "",
                "units": '',
                "description": "",
                "square_feet": ""
            })
            // setMetrics({ purchase_price: '', closing_cost: '', capex: '', reserves: '', entry_noi: '', stabilized_noi: '', pro_noi: '' })
            setEntryNoiSelect({
                purchase_price: true,
                closing_cost: false,
                capex: false,
                reserves: false,
                custom: false,
            })
            setStabilizedNoiSelect({
                purchase_price: true,
                closing_cost: false,
                capex: false,
                reserves: false,
                custom: false,
            })
            setProNoiSelect({
                purchase_price: true,
                closing_cost: false,
                capex: false,
                reserves: false,
                custom: false,
            })
            setTerms()
            // setSeniorDept({
            //     capital_stack: '',
            //     amount: '',
            //     purchase_basis: '',
            //     total_capital_basis: '',
            // })
            setOpCashFlow([])
            setCpCashFlow([])
            setCapitalResult([])
            setOpdata([])
            setCpdata([])
            setData([{
                capital_title: 'Class A Equity Shares',
                capital_stack: '',
                equity: 0,
                LP: 0,
                Sponsor: 0,
                amount: '',
                purchase_basis: 0,
                total_capital_basis: 0,
                pref: '',
                min_equity_multiple: "0",
                irr_hurdle: "0",
                profit_share: ''
            }])
            setCapId('')
            setDataLen(1)
            setHurdle('None')
        }
        console.log("repeact")
    }, [dealId, update])


    // ***** property start ******* //
    const [property, setProperty] = useState({
        "deal_name": "",
        "property_title": "",
        "address": "",
        "city": "",
        "state": "",
        "units": '',
        "description": "",
        "user": 2
    })

    useEffect(() => {
        const storedDealId = localStorage.getItem('currentDealId');
        if (storedDealId) {
            console.log(storedDealId, "this is present ")
            setDealId(storedDealId);
            // Fetch deal data when a deal ID is present
            getData();
            getCashflow();
            getCapitalStack();
            getWaterfall();
            getEquityWaterfall();
            getCapitalResult();
        }

        // Inside your async function
        const handleFetchDeal = async () => {
            try {
                const fetchClient = new MainClient(`/deal/api/view-add-deal/${dealId}/`);
                const response = await fetchClient.getAPI();

                if (response.status === 200) {
                    // If fetch is successful, update state accordingly
                    setVerifiedShared(true);
                } else {
                    // Handle failure
                    setVerifiedShared(false);
                }
            } catch (error) {
                // If there’s any error, set the fetch status to false
                setVerifiedShared(false);
            }
        };

        if (dealId) {
            handleFetchDeal();
        }

    }, [dealId]);


    useEffect(() => {
        // getData()
        getCapitalResult()
    }, [result])
    console.log('newSponsor', property)
    // ***** property end ******* //

    const [entryNoiSelect, setEntryNoiSelect] = useState({
        purchase_price: true,
        closing_cost: false,
        capex: false,
        reserves: false,
        custom: false,
    })
    const [stabilizedNoiSelect, setStabilizedNoiSelect] = useState({
        purchase_price: true,
        closing_cost: false,
        capex: false,
        reserves: false,
        custom: false,
    })
    const [proNoiSelect, setProNoiSelect] = useState({
        purchase_price: true,
        closing_cost: false,
        capex: false,
        reserves: false,
        custom: false,
    })
    console.log('tMetics', metrics)
    const purchase_price = metrics.purchase_price > 0 ? metrics.purchase_price : 0
    const closing_cost = metrics.closing_cost > 0 ? metrics.closing_cost : 0
    const capex = metrics.capex > 0 ? metrics.capex : 0
    const reserves = metrics.reserves > 0 ? metrics.reserves : 0
    // console.log('pmt',parseInt(purchase_price)+parseInt(closing_cost)+parseInt(capex)+parseInt(reserves))
    const total = parseInt(purchase_price) + parseInt(closing_cost) + parseInt(capex) + parseInt(reserves)
    // const total = metrics.purchase_price + metrics.closing_cost + metrics.capex + metrics.reserves
    const entryNoiBasis = [(entryNoiSelect.purchase_price && metrics.purchase_price), (entryNoiSelect.closing_cost && metrics.closing_cost), (entryNoiSelect.capex && metrics.capex), (entryNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    const stabilizedNoiBasis = [(stabilizedNoiSelect.purchase_price && metrics.purchase_price), (stabilizedNoiSelect.closing_cost && metrics.closing_cost), (stabilizedNoiSelect.capex && metrics.capex), (stabilizedNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    const proNoiBasis = [(proNoiSelect.purchase_price && metrics.purchase_price), (proNoiSelect.closing_cost && metrics.closing_cost), (proNoiSelect.capex && metrics.capex), (proNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    // ****** metrics end  ******* //


    // ****** capital stack start  ****** //
    const [seniorDept, setSeniorDept] = useState({
        capital_stack: '',
        amount: '',
        purchase_basis: '',
        total_capital_basis: '',
    })

    const [data, setData] = useState([{
        capital_title: 'Class A Equity Shares',
        capital_stack: '',
        equity: 0,
        LP: 0,
        Sponsor: 0,
        amount: '',
        purchase_basis: 0,
        total_capital_basis: 0,
        pref: '',
        serviceable_pref_return: '',
        accruable_pref_return: '',
        min_equity_multiple: "0",
        irr_hurdle: "0",
        profit_share: ''
    }])
    console.log('logData', data)
    const [dataLen, setDataLen] = useState(1)
    console.log('dataLen', dataLen)
    const classes = ['Class A', 'Class B', 'Class C', 'Class D', 'Class E', 'Class F', 'Class G', 'Class H', 'Class I', 'CLass J']
    const sliceClasses = classes.slice(0, dataLen)
    const classString = sliceClasses.join(' > ')
    const sponsorProfitShare = data?.length > 0 && (1 - data.map(dt => {
        return parseInt(dt.profit_share)
    }).reduce((a, b) => a + b) / 100) * 100
    // ****** capital-stack end  ****** //


    // **** cashflow start ******** //

    const [opCashFlow, setOpCashFlow] = useState([])
    const [cpCashFlow, setCpCashFlow] = useState([])
    const [terms, setTerms] = useState(1)
    let T = []
    const CP = []
    const CashFlow = opCashFlow.map(dt =>
        dt.value && T.push(parseInt(dt.value))
    )

    const SeconedCashFlow = cpCashFlow.map(dt =>
        dt.value && CP.push(parseInt(dt.value))
    )

    let totalOpCashFlow = T.reduce((a, b) => a + b, 0)
    let totalCpCashFlow = CP.reduce((a, b) => a + b, 0)
    // const seniorDebtAmount = total > 0 && seniorDept.capital_stack > 0 ? ((total * parseInt(seniorDept.capital_stack)) / 100).toFixed() : 0

    //   let totalOpCashFlow = opCashFlow[0].value ? opCashFlow.reduce((a, b) => perseInt(a) + parseInt(b), 0) : 0
    //   let totalCpCashFlow = cpCashFlow[0].value ? cpCashFlow.reduce((a, b) => perseInt(a) + parseInt(b), 0) : 0

    // **** cashflow end ******** //


    // **** waterfall start ******** //

    const [Opdata, setOpdata] = useState([])
    const [Cpdata, setCpdata] = useState([])
    // console.log('opDataTest', Opdata)
    // console.log('cpDataTest', Cpdata)

    // **** waterfall end ******** //


    // ***** post data *******  //

    const deal_data = {
        "total_capitalization": total,
        "deal_name": property.deal_name,
        "property_title": property.property_title,
        "address": property.address,
        "city": property.city,
        "state": property.state,
        "units": property.units,
        "purchase_price": metrics.purchase_price,
        "closing_cost": metrics.closing_cost,
        "capex": metrics.capex,
        "reserves": metrics.reserves,
        "square_feet": property.square_feet,
        "entry_noi": metrics.entry_noi,
        "stabilized_noi": metrics.stabilized_noi,
        "proForma_noi": metrics.pro_noi,
        "description": property.description,
        "investment_term": terms,
        "image_url": null,
        "user": user_id,
        "calculator_port": true,
        "noi_data": [
            {
                ...entryNoiSelect,
                "noi_name": "entryNOI",
                "cap_basis": entryNoiBasis,
                "cap_rate": (metrics.stabilized_noi) / entryNoiBasis,
            },
            {
                ...stabilizedNoiSelect,
                "noi_name": "stabilizedNOI",
                "cap_basis": stabilizedNoiBasis,
                "cap_rate": (metrics.stabilized_noi) / stabilizedNoiBasis,
            },
            {
                ...proNoiSelect,
                "noi_name": "proformaNOI",
                "cap_basis": proNoiBasis,
                "cap_rate": (metrics.pro_noi) / proNoiBasis
            }
        ]
    }
    // create section
    console.log('resultx', result)
    const createCashFlow = (id) => {
        const data = {
            "deal_id": id,
            "op_cash_flow_years": opCashFlow,
            "cp_cash_flow_years": cpCashFlow,
            "op_cash_flow_total": totalOpCashFlow,
            "cp_cash_flow_total": totalCpCashFlow
        }
        console.log('cashFlow data', data)
        const fetchClient = new MainClient(`/deal/add-deal-cash-flow/`, data)
        const response = fetchClient.postAPI()
    }
    const createCapitalStack = (id) => {
        if (data?.length > 0) {
            const newData = Object.create(data)
            const cap = newData.map(dt => {
                dt.serviceable_pref_return = dt.serviceable_pref_return == '' || dt.serviceable_pref_return == null ? "0" : dt.serviceable_pref_return
                return dt
            })
            console.log('cap1', cap)
            console.log('cap2', data)
        }
        const cap_data = {
            "deal_id": id,
            "senior_debt": seniorDept.capital_stack,
            "senior_debt_amount": seniorDept.amount,
            "classesData": data,
            "sponsor_profit_share": sponsorProfitShare,
            "hurdle": hurdle
        }
        console.log("cap_data", cap_data)
        const fetchClient = new MainClient(`/deal/stack-creation/`, cap_data)
        const response = fetchClient.postAPI()
    }
    const createWaterfall = (id) => {
        const data = {
            "deal_id": id,
            "op_waterfall": Opdata,
            "cp_waterfall": Cpdata
        }
        console.log('waterfallData', data)
        const fetchClient = new MainClient(`/deal/equity-waterfall/`, data)
        const response = fetchClient.postAPI()
    }
    const imageUpload = (id, image) => {
        let form_data = new FormData()
        form_data.append('images', image, image.name)
        form_data.append('image_data', id)
        const fetchClient = new MainClient('deal/deal-image/', form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        fetchClient.postAPI()
    }
    const documentUpload = (id, file) => {
        let form_data = new FormData()
        form_data.append('file', file, file.name)
        form_data.append('file_name', `${file.name}`)
        form_data.append('upload_document', id)
        const fetchClient = new MainClient('deal/upload-document/', form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        fetchClient.postAPI()
            .catch(err => console.log('upload', err))
    }
    const documentUpdate = (id, d_id, file) => {
        let form_data = new FormData()
        form_data.append('file', file, file.name)
        form_data.append('file_name', `${file.name}`)
        form_data.append('upload_document', id)
        const fetchClient = new MainClient(`/deal/upload-document/${d_id}/`, form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        fetchClient.putAPI()
            .catch(err => console.log('upload', err))
    }
    const imageDelete = (id) => {
        const fetchClient = new MainClient(`/deal/deal-image/${id}/`)
        const response = fetchClient.deleteAPI()
        response.then(() => {
            setUpdate(!update)
            message.success("Image successfully deleted")
        }).error(() => {
            message.success("Image can't deleted")
        })
    }
    console.log('imageData', imageData)
    const submitHandler = async () => {
        setIsLoading(true);
        setSubmit(true);
        setShowResult(false);  // Hide result while processing

        try {
            console.log('Submitting deal data:', deal_data);
            const fetchClient = new MainClient('deal/api/view-add-deal/', deal_data);
            const response = await fetchClient.postAPI();
            let id = response.data.id;
            console.log('Deal created with ID:', id);

            // Handle image uploads
            if (images) {
                for (let i = 0; i < images.length; i++) {
                    let image = images[i];
                    await imageUpload(id, image);
                    console.log(`Uploaded image ${i + 1}`);
                }
            }

            // Handle document upload
            if (document) {
                await documentUpload(id, document);
                console.log('Uploaded document');
            }

            localStorage.setItem('currentDealId', id);
            setDealId(id);

            // Execute API calls in sequence
            console.log('Creating cash flow');
            await createCashFlow(id);

            console.log('Creating capital stack');
            await createCapitalStack(id);

            console.log('Creating waterfall');
            await createWaterfall(id);

            // Handle additional property data
            try {
                const storedData = sessionStorage.getItem("NewPropertyData");
                if (storedData) {
                    const newData = JSON.parse(storedData);
                    for (let i = 0; i < newData.length; i++) {
                        if (newData[i].deal === null) {
                            newData[i].deal = id;
                        }
                        const dataToSend = newData[i];
                        const propertyFetchClient = new MainClient('/deal/api/additonal_propertyinfo_deal/', dataToSend);
                        await propertyFetchClient.postAPI();
                        console.log(`Added additional property info ${i + 1}`);
                    }
                    message.success("Additional property data added successfully");
                    sessionStorage.removeItem("NewPropertyData");
                }
            } catch (error) {
                console.error('Error adding additional property data:', error);
                // message.error("Error adding additional property data. Please try again.");
            }

            // Get the waterfall data after all other APIs have completed
            console.log('Fetching waterfall data');
            const waterfallFetchClient = new MainClient(`/deal/waterfall/${id}/`);
            const waterfallResponse = await waterfallFetchClient.getAPI();
            if (waterfallResponse.data && waterfallResponse.data.response) {
                setResult(waterfallResponse.data.response);
                setErrorMessage(waterfallResponse.data.warning_message);
                console.log('Waterfall data fetched successfully');
            } else {
                console.error('Unexpected response format:', waterfallResponse.data);
                setErrorMessage('Unexpected response format from waterfall data');
                throw new Error('Invalid waterfall data response');
            }

            setShowResult(true);
            setWaterfallTable(true);
            setImages('');
            dispatch(fetchOpenDeal());

        } catch (error) {
            console.error('Error in submit handler:', error);
            // message.error('An error occurred while saving the deal. Please try again.');
        } finally {
            setSubmit(false);
            setIsLoading(false);
        }
    };
    // console.log("Steepr Deal Id is",id);
    // console.log("the id is ID",id);


    // sessionStorage.setItem("NewPropertyData", JSON.stringify(newData));

    // localStorage.removeItem("NewPropertyData");                


    // update section
    const updateCashFlow = () => {
        // const Id = cashFlow[0].id
        const data = {
            "deal_id": dealId,
            "op_cash_flow_years": opCashFlow,
            "cp_cash_flow_years": cpCashFlow,
            "op_cash_flow_total": totalOpCashFlow,
            "cp_cash_flow_total": totalCpCashFlow
        }
        console.log('cashFlowId', cashFlowId)
        const fetchClient = new MainClient(`/deal/add-deal-cash-flow/${cashFlowId}/`, data)
        const response = fetchClient.putAPI()
        response
            .then(res => {
                setOpCashFlow(res.data.op_cash_flow_years)
                setCpCashFlow(res.data.cp_cash_flow_years)
                setCashFlowId(res.data.id)
            })
            .catch(err => console.log(err))
    }
    const updateCapitalStack = () => {
        if (data?.length > 0) {
            const newData = Object.create(data)
            const cap = newData.map(dt => {
                dt.serviceable_pref_return = dt.serviceable_pref_return == '' || dt.serviceable_pref_return == null ? "0" : dt.serviceable_pref_return
                return dt
            })
            console.log('cap1', cap)
            console.log('cap2', data)
        }
        const cap_data = {
            "deal_id": dealId,
            "senior_debt": seniorDept.capital_stack,
            "senior_debt_amount": seniorDept.amount,
            "classesData": data,
            "sponsor_profit_share": sponsorProfitShare,
            "hurdle": hurdle
        }
        console.log("submit_cap_data", cap_data)
        const fetchClient = new MainClient(`/deal/stack-creation/${capId}/`, cap_data)
        const response = fetchClient.putAPI()
    }
    console.log("cap_data", data)
    const updateWaterfall = () => {
        const data = {
            "deal_id": dealId,
            "op_waterfall": Opdata,
            "cp_waterfall": Cpdata
        }
        console.log('waterfallupdate', data)
        const fetchClient = new MainClient(`/deal/equity-waterfall/${waterfallId}/`, data)
        const response = fetchClient.putAPI()
        if (response.data) {
            setResult(response.data);
        }

    }
    const updateDeal = () => {
        const fetchClient = new MainClient(`/deal/api/view-add-deal/${dealId}/`, deal_data)
        console.log('deal_data', deal_data)
        fetchClient.putAPI()
    }

    // Additional Propert
    const AddAdditionalProperty = () => {
        try {
            const storedData = sessionStorage.getItem("NewPropertyData");
            // Parse the stored data from a string to a JavaScript object
            const newData = JSON.parse(storedData);

            for (let i = 0; i < newData.length; i++) {
                if (newData[i].deal === null) {
                    newData[i].deal = id;
                }

                const dataToSend = newData[i];

                const fetchClient = new MainClient(
                    '/deal/api/additonal_propertyinfo_deal/',
                    dataToSend
                );
                const response = fetchClient.postAPI();
                response
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                        // message.error("There was an error adding your data. Please try again.");
                    });

                // axios.post("http://127.0.0.1:8000/deal/api/additonal_propertyinfo_deal/", dataToSend)
                //   .then(response => {
                //     console.log(response);
                //   })
                //   .catch(error => {
                //     console.log(error);
                //     message.error("There was an error adding your data. Please try again.");
                //   });
            }
            message.success("Your Data Added");
            sessionStorage.removeItem("NewPropertyData");
        } catch (error) {
            return;
        }
    };


    const updateHandler = () => {
        setSubmit(true)
        const fetchClient = new MainClient(`/deal/api/view-add-deal/${dealId}/`, deal_data)
        console.log('deal_data', deal_data)
        const response = fetchClient.putAPI()
        response.then(res => {
            console.log('updatedata', res.data)
            let id = res.data.id
            if (images) {
                for (let i = 0; i < images.length; i++) {
                    let image = images[i]
                    imageUpload(id, image)


                }
            }
            if (documentData.length > 0 || document) {
                if (documentData.length > 0) {
                    let d_id = documentData[0].id
                    console.log('da2_id', d_id)
                    if (document) {
                        documentUpdate(id, d_id, document)
                    }
                }
                else {
                    documentUpload(id, document)
                }
            }



            AddAdditionalProperty()
            updateDeal()
            updateCashFlow()
            updateCapitalStack()
            updateWaterfall()
            setUpdate(!update)
            setSubmit(false)
            window.location.reload();
        })
    }

    const handleSaveOrUpdate = async () => {
        setIsLoading(true);
        try {
            if (dealId) {
                await updateHandler();
                message.success('Calculator Updated');
            } else {
                await submitHandler();
                message.success('Calculator Created');
            }
        } catch (error) {
            console.error('Error in save/update operation:', error);
            // message.error('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const generateRandomNumber = () => {
        const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
        return randomNumber;
    };

    const generateLink = () => {
        if (!linkRef.current) {
            const randomNum = generateRandomNumber();
              const uniqueURL = `${mainClientInstance.instance.defaults.baseURL}/deal-calculator/${randomNum}`;
            // const uniqueURL = `http://localhost:3000/deal-calculator/${randomNum}`;
            linkRef.current = uniqueURL;
        }
        return linkRef.current;
    };

    const handleButtonClick = async () => {
        try {

            const fetchClient = new MainClient("/deal/api/sharecalculator/");
            const response = await fetchClient.getNewPublicUrl();
            const data = response.data;
            console.log("My saved data is", data);
            let foundUrl = false;

            data.forEach((item) => {
                if (item.deal_id === parseInt(id)) {
                    if (item.deal_user.split(",")[0] === currentUserEmail.split(",")[0]) {
                        console.log("Url Token", item.url_link);
                        setPublicUrlPage(item.url_link);
                        foundUrl = true;

                    }
                }
            });

            console.log("Public Url is ", PublicUrlPage);


            if (!foundUrl) {

                if (!PublicUrlPage) {
                    setShowModal(true);
                    const uniqueURL = generateLink();
                    setNewPublicUrl(uniqueURL)
                    console.log("current user email", currentUserEmail);

                    const data = {
                        deal_id: dealId,
                        deal_user: currentUserEmail,
                        url_link: uniqueURL,
                        token: localStorage.getItem("token"),
                    };

                    const fetchClient = new MainClient("/deal/api/sharecalculator/", data);
                    const response = await fetchClient.postAPI() // Await the response here

                    console.log(response);
                    // Check if the PublicUrlPage variable has already been set
                    if (PublicUrlPage) {
                        // Do not copy the URL to the clipboard
                    } else {
                        // Copy the uniqueURL to the clipboard
                        await navigator.clipboard
                            .writeText(uniqueURL)
                            .then(() => {
                                // message.success("Link copied to clipboard");
                            })
                            .catch((error) => {
                                // message.error("Please manually copy the link");
                                // console.error("Failed to copy link to clipboard:", error);
                            });
                    }
                } else {
                    // Copy the PublicUrlPage to the clipboard
                    await navigator.clipboard
                        .writeText(PublicUrlPage)
                        .then(() => {
                            // message.success("Link copied to clipboard");
                        })
                        .catch((error) => {
                            // message.error("Please manually copy the link");
                            // console.error("Failed to copy link to clipboard:", error);
                        });
                }
            } else {
                setShowModal(true);
                // Copy the PublicUrlPage to the clipboard
                navigator.clipboard
                    .writeText(PublicUrlPage)
                    .then(() => {
                        // message.success("Link copied to clipboard");
                    })
                    .catch((error) => {
                        // message.error("Please manually copy the link");
                        console.error("Failed to copy link to clipboard:", error);
                    });
            }


        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleCopyLink = async () => {
        navigator.clipboard.writeText(NewPublicUrl || PublicUrlPage).then(() => {
            message.success("Link copied to clipboard");
        }).catch((error) => {
            message.error("Please manually copy the link");
            console.error("Failed to copy link to clipboard:", error);
        });
        setShowModal(false);
    };

    let message = 
    <>
                <Navbar variant="light" shadow style={{ backgroundColor: "#1561ad" }}>
                <Container className="d-flex justify-content-center">
                    <Link to="/dashboard/main">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/icon%2Fstackshare%20logo.png?alt=media&token=23fea7a6-8ab0-44e8-b7cc-3d77c7a27a79"
                            alt="StackShare Logo"
                            height="30"
                            className="d-inline-block align-center"
                            style={{ filter: "brightness(0) invert(1)" }}
                        />
                    </Link>
                </Container>
            </Navbar>
    <div className='p-5 pt-1'>

        <MetricsCalculator
            metrics={metrics}
            setMetrics={setMetrics}
            entryNoiSelect={entryNoiSelect}
            setEntryNoiSelect={setEntryNoiSelect}
            stabilizedNoiSelect={stabilizedNoiSelect}
            setStabilizedNoiSelect={setStabilizedNoiSelect}
            proNoiSelect={proNoiSelect}
            setProNoiSelect={setProNoiSelect}
            updateHandler={updateHandler}
            submit={submit}
            id={id}
            total={total}
            // update={false}
        />
        <div className='card-container'>
            <CashFlows
                opCashFlow={opCashFlow}
                setOpCashFlow={setOpCashFlow}
                cpCashFlow={cpCashFlow}
                setCpCashFlow={setCpCashFlow}
                terms={terms}
                setTerms={setTerms}
                updateHandler={updateHandler}
                submit={submit}
                id={id}
                enableUpdate={false}
            />
        </div>
        <div>
            <div className='card-container'>
                <CapitalStack
                    totalCapital={total}
                    purchasePrice={metrics.purchase_price}
                    seniorDept={seniorDept}
                    setSeniorDept={setSeniorDept}
                    data={data}
                    setData={setData}
                    setDataLen={setDataLen}
                    hurdle={hurdle}
                    setHurdle={setHurdle}
                    errorMessage={errorMessage}
                />
            </div>
            <div className='card-container'>
                <WaterfallCalculator
                    classString={classString}
                    Opdata={Opdata}
                    setOpdata={setOpdata}
                    Cpdata={Cpdata}
                    setCpdata={setCpdata}
                    submitHandler={submitHandler}
                    updateHandler={updateHandler}
                    submit={submit}
                />
            </div>
            {((!id && !verifiedShared) || (!id && verifiedShared)) && (
                <div className="d-flex justify-content-between"> {/* Flex container for buttons */}
                    <Button
                        variant='contained'
                        className='px-5 me-2'
                        style={{ backgroundColor: "#2B4479" }}
                        onClick={handleSaveOrUpdate}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Processing...' : dealId ? 'Update' : 'Save'}
                    </Button>

                    {dealId && (
                        <>
                            <Button
                                variant='contained'
                                className='px-5'
                                style={{ backgroundColor: "#2B4479" }}
                                onClick={handleDelete}
                                disabled={isLoading}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="contained"
                                className='px-5'
                                style={{ backgroundColor: "#2B4479" }}
                                onClick={handleButtonClick}
                            >
                                Share
                                <ReplyIcon
                                    style={{ transform: "scaleX(-1)", transition: "revert" }}
                                />
                            </Button>

                        </>
                    )}
                </div>
            )}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Share Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Generated Link:{" "}
                        {PublicUrlPage ? PublicUrlPage : generateLink()}{" "}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>

                    <Button variant="primary" onClick={handleCopyLink}>
                        Copy
                    </Button>
                </Modal.Footer>
            </Modal>

            {result ? (
                <>
                    <div className='card-container'>
                        <Result data={result} opData={Opdata} />
                    </div>
                    <div className="card-container">
                        <CapitalResult data={result} cpData={Cpdata} />
                    </div>
                    <div className="card-container">
                        <FinalResult data={result} />
                    </div>
                </>
            ) : null}
        </div>
    </div>
    </>

    if (dealId) {
        if (metrics.purchase_price) {
            return message

        }
    }
    else {
        return message
    }


    return (
        <div>
            <>
                {message}
            </>
        </div>
    );
};

export default DealCalculator