import MainClient from "../../utilities/MainClient"
import { dealActions } from "../reducers/DealReducers"

export const fetchOpenDeal = () => {
    return async (dispatch) => {
        const getData = async () => {
            const fetchClient = new MainClient(`/deal/get-deals?deal_status=Open Deals`)
            const response = await fetchClient.getAPI()
            const data = await response.data.result
            return data.filter(deal => deal.deal_status === 'Open')
        }
        try {
            const data = await getData()
            dispatch(dealActions.replaceOpenDeal(data.reverse()))
            // console.log('storedata', data)
        } catch (error) {
            console.log("storedataerror", error)
        }
    }
}

export const fetchCloseDeal = () => {
    return async (dispatch) => {
        const getData = async () => {
            const fetchClient = new MainClient(`/deal/get-deals?deal_status=Closed Deals`)
            const response = await fetchClient.getAPI()
            const data = await response.data.result
            return data
        }
        try {
            const data = await getData()
            dispatch(dealActions.replaceCloseDeal(data.reverse()))
            console.log('storedata', data)
        } catch (error) {
            console.log("storedataerror", error)
        }
    }
}